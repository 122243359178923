import React from "react";
import { IoMdExpand } from "react-icons/io";
import { useZafClient } from "../../utils/zafClient";
import { ApiConfig } from "../../Config/ApiConfig";
import { Tooltip as ReactTooltip } from "react-tooltip";
const ExpandModal = () => {
  const client = useZafClient();

  const handleClick = async () => {
    try {
      const contextData = await client?.context?.();
      const currentTicket = await client?.get?.("ticket");
      const currentUser = await client?.get?.(["currentUser"]);

      // Save to localStorage
      localStorage.setItem("contextData", JSON.stringify(contextData));
      localStorage.setItem("currentTicket", JSON.stringify(currentTicket));
      localStorage.setItem("currentUser", JSON.stringify(currentUser));

      await client?.invoke?.("instances.create", {
        location: "modal",
        url: ApiConfig.modal, // Update this to point to your modal content
        size: {
          width: "80vw", // Adjust width as needed
          height: "80vh", // Adjust height as needed
        },
      });

      //   const location = modalContext?.["instances.create"]?.[0]?.location;
    } catch (error) {}
  };

  return (
    <>
      <IoMdExpand
        className="grow-icon"
        data-tooltip-id="expand"
        style={{
          cursor: "pointer",
          fontSize: "1.5rem",
          width: "30px",
          height: "30px",
        }}
        onClick={handleClick}
      />
      <ReactTooltip
        id="expand"
        place="top"
        content={`Expand`}
        style={{ zIndex: "9999" }}
        delayShow={800}
      />
    </>
  );
};

export default ExpandModal;
