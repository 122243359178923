import React from "react";
import ReactECharts from "echarts-for-react";
import { Card } from "react-bootstrap";

const HeatMap = ({ heatMapData }) => {
  if (!heatMapData || Object.keys(heatMapData).length === 0) {
    return (
      <Card className="border border-0 shadow">
        <Card.Header className="p-2 bg-light border border-0">
          <h6 className="mb-0">Heat Map</h6>
        </Card.Header>
        <Card.Body>
          <h5 className="text-muted text-center my-3">No Data Found</h5>
        </Card.Body>
      </Card>
    );
  }

  // Function to get all KPIs except "Average"
  const getKPIs = (heatMapData) => {
    const dates = Object.keys(heatMapData);
    const latestDate = dates[dates.length - 2]; // Second last is the latest date (ignore "Category_Averages")
    const kpis = Object.entries(heatMapData[latestDate]);

    // Return all KPIs except "Average"
    return kpis.filter(([kpi]) => kpi !== "Average").map(([kpi]) => kpi);
  };

  const dates = Object.keys(heatMapData);
  const kpis = getKPIs(heatMapData); // Get all KPIs except "Average"

  // Prepare data for the scrollable KPIs
  const kpiData = [];
  dates.reverse().forEach((date, i) => {
    kpis.forEach((category, j) => {
      const value = heatMapData[date][category] || 0;
      if (value !== 0) {
        kpiData.push([j, i, value]); // Only push non-zero values
      }
    });
  });

  // Prepare data for the fixed "Average" column
  const averageData = [];
  dates.forEach((date, i) => {
    const value = heatMapData[date]["Average"] || 0;
    averageData.push([0, i, value]); // Keep "Average" as the only x-axis value (fixed)
  });

  // Heatmap option configuration
  const option = {
    tooltip: {
      position: "top",
      formatter: function (params) {
        // Safeguard to check params existence
        if (
          !params ||
          !params.data ||
          !Array.isArray(params.data) ||
          params.data.length < 3
        ) {
          return "No data";
        }

        const dateIndex = params.data[1]; // Safely access the date index
        const date = dates[dateIndex] || "Unknown Date";
        const category = kpis[params.data[0]] || "Unknown Category";
        const value =
          params.data[2] !== undefined ? params.data[2].toFixed(1) : "No data";

        return `Date: ${date}<br/>
                Category: ${category}<br/>
                Value: ${value}%`; // Add '%' sign to display
      },
    },
    grid: [
      {
        top: "2%", // Adjusted top for the scrollable grid
        bottom: "10%", // Adjusted bottom for the scrollable grid
        left: "2%", // Left for the main scrollable columns
        right: "12%", // Leave room for the fixed "Average" column
        containLabel: true,
      },
      {
        top: "2%", // Same as the main grid for consistency
        bottom: "10%", // Same bottom as the main grid
        left: "88%", // Left for the fixed "Average" column
        right: "0%", // Fully extend to the right edge
        containLabel: true,
      },
    ],
    xAxis: [
      {
        type: "category",
        data: kpis,
        gridIndex: 0,
        position: "top",
        splitArea: {
          show: true,
        },
        axisLabel: {
          interval: 0, // Show all labels
          formatter: function (value) {
            return value.split("_").join("\n"); // Split label text into two lines
          },
          fontSize: 10,
        },
        axisTick: {
          alignWithLabel: true,
        },
      },
      {
        type: "category",
        data: ["Average"], // Fixed "Average" column
        gridIndex: 1,
        position: "top",
        splitArea: {
          show: true,
        },
        axisLabel: {
          fontSize: 10,
        },
        axisTick: {
          alignWithLabel: true,
        },
      },
    ],
    yAxis: [
      {
        type: "category",
        data: dates, // Dates and "Category_Averages" on the y-axis
        gridIndex: 0,
        splitArea: {
          show: true,
        },
        axisLabel: {
          interval: 0, // Show all labels
          formatter: function (value) {
            return value.split("_").join("\n"); // Split label text into two lines
          },
          fontSize: 10,
        },
      },
      {
        type: "category",
        data: dates, // Sync the y-axis with the main grid
        gridIndex: 1,
        splitArea: {
          show: true,
        },
        axisLabel: {
          show: false, // Hide y-axis labels for "Average" column
        },
      },
    ],
    dataZoom: [
      {
        type: "slider", // Enables horizontal scrolling
        xAxisIndex: 0,
        start: 0, // Fixed start for the scrollable columns
        end: 25, // Scrollable range for KPIs
        zoomLock: true,
      },
    ],
    visualMap: {
      show: false,
      min: 1,
      max: 100,
      inRange: {
        color: ["rgb(240,99,63,1)", "rgb(222,203,14,1)", "rgb(139,172,29,1)"],
      },
      calculable: true,
      orient: "horizontal",
      left: "center",
      bottom: "15%",
    },
    series: [
      {
        name: "HeatMap",
        type: "heatmap",
        data: kpiData,
        label: {
          show: true,
          fontSize: 10, // Increased font size for labels
          fontWeight: "bold", // Make the label text bold
          color: "#000", // Label color
          formatter: function (params) {
            return `${params.value[2].toFixed(0)}%`; // Display percentage in bold
          },
        },
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowColor: "rgba(0, 0, 0, 0.5)",
          },
        },
        xAxisIndex: 0,
        yAxisIndex: 0,
      },
      {
        name: "Average",
        type: "heatmap",
        data: averageData,
        label: {
          show: true,
          fontSize: 10, // Increased font size for labels
          fontWeight: "bold", // Make the label text bold
          color: "#000", // Label color
          formatter: function (params) {
            return `${params.value[2].toFixed(0)}%`; // Display percentage in bold
          },
        },
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowColor: "rgba(0, 0, 0, 0.5)",
          },
        },
        xAxisIndex: 1,
        yAxisIndex: 1,
      },
    ],
  };

  return (
    <Card className="border border-0 shadow">
      <Card.Header className="p-2 bg-light border border-0">
        <h6 className="mb-0">Heat Map</h6>
      </Card.Header>
      <Card.Body>
        <ReactECharts
          option={option}
          style={{ height: "400px", width: "100%" }}
        />
      </Card.Body>
    </Card>
  );
};

export default HeatMap;
