import React, { useState, useEffect, useRef } from "react";
import { Row, Col, Container, Card } from "react-bootstrap";
import {
  FaUser,
  FaEnvelope,
  FaGlobe,
  FaMobileAlt,
  FaComments,
} from "react-icons/fa";
import { getKpiColorMapping } from "../Helper/ColorMapping";
import stringSimilarity from "string-similarity";

function TicketComments({ ticketData, ticketId, selectedKpis }) {
  const sentimentMapping = {
    10: "😊", // Very Positive
    8: "😊", // Positive
    6: "😐", // Neutral
    4: "😞", // Negative
    2: "😞", // Very Negative
  };

  const empathyMapping = {
    10: "💖", // High Empathy
    8: "💖", // High Empathy
    6: "👍", // Medium Empathy
    4: "😔", // Low Empathy
    2: "😔", // Very Low Empathy
  };

  const defaultIcon = "❓"; // Default emoji if no sentiment/empathy is found

  // Function to determine sentiment based on range
  const getSentimentEmoji = (value) => {
    if (value >= 8) return sentimentMapping[10];
    if (value >= 6) return sentimentMapping[8];
    if (value >= 4) return sentimentMapping[6];
    if (value >= 2) return sentimentMapping[4];
    return sentimentMapping[2];
  };

  // Function to determine empathy based on range
  const getEmpathyEmoji = (value) => {
    if (value >= 8) return empathyMapping[10];
    if (value >= 6) return empathyMapping[8];
    if (value >= 4) return empathyMapping[6];
    if (value >= 2) return empathyMapping[4];
    return empathyMapping[2];
  };

  let customerSentiment =
    getSentimentEmoji(
      ticketData?.conversations?.topcx_qa_scores?.interaction?.sentiment?.score
    ) || defaultIcon;
  let agentEmpathy =
    getEmpathyEmoji(
      ticketData?.conversations?.topcx_qa_scores?.interaction?.empathy?.score
    ) || defaultIcon;

  const commentsEndRef = useRef(null); // Create a reference to the end of the comments list
  const [colorMapping, setColorMapping] = useState({});

  useEffect(() => {
    if (ticketData) {
      const colors = getKpiColorMapping(
        ticketData?.conversations?.topcx_qa_scores || {}
      );
      setColorMapping(colors);
    }
  }, [ticketData]);

  useEffect(() => {
    scrollToBottom(); // Scroll to bottom when component mounts or comments change
  }, [ticketData]);

  const scrollToBottom = () => {
    if (commentsEndRef.current) {
      commentsEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  if (!ticketData || !ticketId) {
    return (
      <Card className="border-0 shadow">
        <Card.Header
          className="p-2 bg-light border-0 fw-bold"
          style={{ fontSize: "12px" }}
        >
          <h6 className="mb-0">Ticket Comments</h6>
        </Card.Header>
        <Card.Body>
          <h5 className="text-muted text-center my-3">No Data Found</h5>
        </Card.Body>
      </Card>
    );
  }

  const comments = ticketData?.conversations?.ticket_comments || [];
  const userId = comments[0]?.author_id;

  const getChannelDetails = (channel) => {
    switch (channel) {
      case "email":
        return {
          icon: <FaEnvelope />,
          text: "EMAIL",
        };
      case "web":
        return {
          icon: <FaGlobe />,
          text: "WEB",
        };
      case "mobile":
        return {
          icon: <FaMobileAlt />,
          text: "MOBILE",
        };
      case "chat":
        return {
          icon: <FaComments />,
          text: "CHAT",
        };
      default:
        return {
          icon: <FaGlobe />,
          text: channel.toUpperCase(),
        };
    }
  };

  const sanitizeText = (text) => {
    return text
      .replace(/<[^>]+>/g, "") // Remove HTML tags
      .replace(/\s+/g, " ") // Normalize whitespace
      .trim() // Remove leading/trailing whitespace
      .toLowerCase(); // Convert to lowercase for consistent comparison
  };

  const sentencesMap = {};
  Object.entries(selectedKpis).forEach(([key, selected]) => {
    if (selected) {
      const [category, metric] = key.split("_");
      const sentences =
        ticketData.conversations.topcx_qa_scores[category]?.[metric]
          ?.sentences || [];

      sentences.forEach((sentence) => {
        const sanitizedSentence = sanitizeText(sentence);
        if (!sentencesMap[sanitizedSentence]) {
          sentencesMap[sanitizedSentence] = colorMapping[key];
        }
      });
    }
  });

  return (
    <Card className="border-0 shadow">
      <Card.Header className="p-2 bg-light border-0">
        <div className="d-flex justify-content-between">
          {/* Customer Sentiment */}
          <div className="d-flex flex-column align-items-center">
            <span style={{ fontSize: "10px" }} className="fw-bold">
              Customer Sentiment
            </span>
            <span style={{ fontSize: "12px" }}>{customerSentiment}</span>
          </div>

          {/* Agent Empathy */}
          <div className="d-flex flex-column align-items-center">
            <span style={{ fontSize: "10px" }} className="fw-bold">
              Agent Empathy
            </span>
            <span style={{ fontSize: "12px" }}>{agentEmpathy}</span>
          </div>

          {/* Ticket Time */}
          <div className="d-flex flex-column align-items-center">
            <span style={{ fontSize: "10px" }} className="fw-bold">
              Time to Solve
            </span>
            <span style={{ fontSize: "12px" }}>
              {ticketData?.ticket_time || defaultIcon}
            </span>
          </div>

          {/* Ticket Comments Length */}
          <div className="d-flex flex-column align-items-center">
            <span style={{ fontSize: "10px" }} className="fw-bold">
              Comments
            </span>
            <span style={{ fontSize: "12px" }}>
              {ticketData?.ticket_comments_length || defaultIcon}
            </span>
          </div>
        </div>
      </Card.Header>
      <Card.Body>
        <Container style={{ maxHeight: "350px", overflowY: "auto" }}>
          {comments.map((comment, index) => {
            const channelDetails = getChannelDetails(comment.via.channel);
            const isUserComment = comment.author_id === userId;

            return (
              <Row
                key={index}
                className={`my-3 d-flex ${
                  isUserComment
                    ? "justify-content-start"
                    : "justify-content-end"
                }`}
              >
                <Col xs="auto">
                  <div
                    className={`d-flex align-items-center ${
                      isUserComment ? "" : "flex-row-reverse text-end"
                    }`}
                  >
                    <div>
                      <div
                        className={`d-flex align-items-center ${
                          isUserComment ? "" : "justify-content-end"
                        }`}
                        style={{ fontSize: "14px" }}
                      >
                        <FaUser size={10} className="me-2" />
                        <strong>{comment.author_name}</strong>
                        <span className={`ms-2 badge text-bg-light`}>
                          <span className="me-1"> {channelDetails?.icon}</span>

                          <span className="my-auto">{channelDetails.text}</span>
                        </span>
                      </div>
                      <div
                        className={`text-muted ${
                          isUserComment ? "" : "text-end"
                        }`}
                        style={{ fontSize: "10px" }}
                      >
                        {new Date(comment.created_at).toLocaleString("en-US", {
                          weekday: "short",
                          year: "numeric",
                          month: "long",
                          day: "numeric",
                          hour: "2-digit",
                          minute: "2-digit",
                          second: "2-digit",
                        })}
                      </div>
                    </div>
                  </div>
                  <div
                    className="p-2 mt-2 text-black"
                    style={{
                      borderRadius: "10px",
                      maxWidth: "550px",
                      fontSize: "14px",
                      backgroundColor: isUserComment ? "#F8F9F9" : "#EBF9F9",
                    }}
                  >
                    {comment?.html_body
                      .split(/([.!?]\s+|\n)/g)
                      .map((sentence, i) => {
                        const sanitizedSentence = sanitizeText(sentence);

                        const bestMatch =
                          Object.keys(sentencesMap)?.length > 0 &&
                          stringSimilarity.findBestMatch(
                            sanitizedSentence,
                            Object.keys(sentencesMap)
                          );

                        const highlightColor =
                          bestMatch?.bestMatch?.rating > 0.6
                            ? sentencesMap[bestMatch.bestMatch.target]
                            : "";

                        return (
                          <span
                            key={i}
                            style={{ backgroundColor: highlightColor }}
                          >
                            {sentence}
                          </span>
                        );
                      })}
                  </div>
                </Col>
              </Row>
            );
          })}
          <div ref={commentsEndRef} />
        </Container>
      </Card.Body>
      <Card.Footer className="bg-light p-2  border border-0">
        <span className="ms-2 fw-bold">#&nbsp;{ticketId || "*****"}</span>
      </Card.Footer>
    </Card>
  );
}

export default TicketComments;
