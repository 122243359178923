import React, { useState, useEffect } from "react";
import { Badge, Card, Table, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
export default function TicketReview({ ticketData }) {
  const [activeTab, setActiveTab] = useState("dignified");
  const [dignifiedTickets, setDignifiedTickets] = useState([]);
  const [neutralTickets, setNeutralTickets] = useState([]);
  const [criticalTickets, setCriticalTickets] = useState([]);
  const [expandedTickets, setExpandedTickets] = useState({}); // Track expanded state for each ticket

  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
  };

  const toggleExpand = (ticketNumber) => {
    setExpandedTickets((prevState) => ({
      ...prevState,
      [ticketNumber]: !prevState[ticketNumber],
    }));
  };

  // Function to format date and time
  const formatDateTime = (dateTimeStr) => {
    const date = new Date(dateTimeStr);
    const options = {
      weekday: "short",
      year: "numeric",
      month: "long",
      day: "numeric",
    };
    const formattedDate = date.toLocaleDateString("en-US", options);
    const formattedTime = date.toLocaleTimeString("en-US", {
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: true,
    });

    return `${formattedDate} at ${formattedTime}`;
  };

  // Function to determine the appropriate emoji based on sentiment
  const getSentimentEmoji = (sentimentScore, empathyScore) => {
    // Mapping based on sentiment score
    const sentimentEmoji = (score) => {
      if (score >= 8) return "😊"; // Positive
      if (score >= 4) return "😐"; // Neutral
      return "😞"; // Negative
    };

    // Mapping based on empathy score
    const empathyEmoji = (score) => {
      if (score >= 8) return "💖"; // High empathy
      if (score >= 4) return "👍"; // Medium empathy
      return "😔"; // Low empathy
    };

    return `${sentimentEmoji(
      sentimentScore
    )}\u00A0\u00A0\u00A0\u00A0${empathyEmoji(empathyScore)}`;
  };

  useEffect(() => {
    const processTickets = (data) => {
      if (!data || !data.high_rated || !data.neutral || !data.critical) return;

      const highRated = (data.high_rated || []).map((ticket) => ({
        ticket_number: ticket.ticket_id,
        customer_sentiment:
          ticket?.topcx_qa_scores?.interaction?.sentiment?.score,
        agent_empathy: ticket?.topcx_qa_scores?.interaction?.empathy?.score,
        reason: ticket.qa_classification_reasons?.high_rated || [],
        date: formatDateTime(ticket.solved_at),
      }));

      setDignifiedTickets(highRated);

      const neutral = (data.neutral || []).map((ticket) => ({
        ticket_number: ticket.ticket_id,
        customer_sentiment:
          ticket?.topcx_qa_scores?.interaction?.sentiment?.score,
        agent_empathy: ticket?.topcx_qa_scores?.interaction?.empathy?.score,
        reason: ticket.qa_classification_reasons?.neutral || [],
        date: formatDateTime(ticket.solved_at),
      }));
      setNeutralTickets(neutral);

      const critical = (data.critical || []).map((ticket) => ({
        ticket_number: ticket.ticket_id,
        customer_sentiment:
          ticket?.topcx_qa_scores?.interaction?.sentiment?.score,
        agent_empathy: ticket?.topcx_qa_scores?.interaction?.empathy?.score,
        reason: ticket.qa_classification_reasons?.critical || [],
        date: formatDateTime(ticket.solved_at),
      }));
      setCriticalTickets(critical);

      if (highRated.length > 0) {
        setActiveTab("dignified");
      } else if (neutral.length > 0) {
        setActiveTab("neutral");
      } else if (critical.length > 0) {
        setActiveTab("critical");
      } else {
        setActiveTab("dignified");
      }
    };

    if (ticketData) {
      processTickets(ticketData);
    }
  }, [ticketData]);

  // If no ticket data, show 'No Data Found' card
  if (!ticketData) {
    return (
      <Card className="border border-0 shadow">
        <Card.Header className="p-2 bg-light border border-0">
          <h6 className="mb-0">Ticket Review</h6>
        </Card.Header>
        <Card.Body>
          <h5 className="text-muted text-center my-3">No Data Found</h5>
        </Card.Body>
      </Card>
    );
  }

  const renderTableRows = (data = []) => {
    if (data.length === 0) {
      return (
        <tr>
          <td colSpan="4" className="text-center text-muted">
            <h5>No Data Found</h5>
          </td>
        </tr>
      );
    }
    return data.map((ticket, index) => {
      const expanded = expandedTickets[ticket.ticket_number];
      const itemsToShow = expanded ? ticket.reason.length : 2;
      return (
        <tr key={index}>
          <td>#{ticket.ticket_number}</td>
          <td>
            {getSentimentEmoji(ticket.customer_sentiment, ticket.agent_empathy)}
          </td>
          <td>
            <div className="m-0 text-wrap" style={{ maxWidth: "35rem" }}>
              {ticket.reason.slice(0, itemsToShow).map((reason, idx) => (
                <Badge
                  bg={
                    activeTab === "dignified"
                      ? "success"
                      : activeTab === "neutral"
                      ? "secondary"
                      : "danger"
                  }
                  className="me-1 mb-1"
                  key={idx}
                  style={{ fontWeight: "bold" }}
                >
                  {reason}
                </Badge>
              ))}
              {ticket.reason.length > 3 && (
                <Button
                  variant="link"
                  className="p-0 ms-2"
                  onClick={() => toggleExpand(ticket.ticket_number)}
                  style={{ fontSize: "10px" }}
                >
                  {expanded ? "See Less..." : "See More..."}
                </Button>
              )}
            </div>
          </td>
          <td>{ticket.date}</td>
        </tr>
      );
    });
  };

  const renderTable = (data = []) => {
    return (
      <Table responsive>
        <thead className="bg-light">
          <tr>
            <th>Ticket Number</th>
            <th>Customer Sentiment / Agent Empathy</th>
            <th>Reason</th>
            <th>Date</th>
          </tr>
        </thead>
        <tbody>{renderTableRows(data)}</tbody>
      </Table>
    );
  };

  return (
    <Card className="border border-0 shadow">
      <Card.Header className="p-2 bg-light border border-0">
        <h6 className="mb-0">Ticket Review</h6>
      </Card.Header>
      <Card.Body>
        <ul className="nav nav-underline nav-fill">
          <li className="nav-item">
            <Link
              className={`nav-link ${
                activeTab === "dignified" ? "active" : ""
              } p-0`}
              onClick={() => handleTabClick("dignified")}
            >
              Dignified
            </Link>
          </li>
          <li className="nav-item">
            <Link
              className={`nav-link ${
                activeTab === "neutral" ? "active" : ""
              } p-0`}
              onClick={() => handleTabClick("neutral")}
            >
              Neutral
            </Link>
          </li>
          <li className="nav-item">
            <Link
              className={`nav-link ${
                activeTab === "critical" ? "active" : ""
              } p-0`}
              onClick={() => handleTabClick("critical")}
            >
              Critical
            </Link>
          </li>
        </ul>
        <hr className="m-0 p-0 mb-2" />

        {activeTab === "dignified" && renderTable(dignifiedTickets)}
        {activeTab === "neutral" && renderTable(neutralTickets)}
        {activeTab === "critical" && renderTable(criticalTickets)}
      </Card.Body>
    </Card>
  );
}
