import React, { useEffect, useRef, useState } from "react";
import {
  Row,
  Col,
  Card,
  Form,
  Button,
  InputGroup,
  Badge,
  Dropdown,
  Accordion,
} from "react-bootstrap";
import {
  FaUser,
  FaEnvelope,
  FaGlobe,
  FaMobileAlt,
  FaComments,
} from "react-icons/fa";
import { LuFilter } from "react-icons/lu";
import { BsTranslate } from "react-icons/bs";
import ExpandModal from "../../ExpandModal/ExpandModal";

// A mock translation function (replace with actual translation API call)
const textTranslate = async (text) => {
  try {
    const result = `こんにちは、お元気ですか ${text}`;
    return result;
  } catch (error) {
    console.error("Translation error:", error);
    return "Translation failed";
  }
};

function TicketComments({ ticketData, client, isModal }) {
  const commentsEndRef = useRef(null);
  const [newComment, setNewComment] = useState("");
  const [commentType, setCommentType] = useState("public"); // Default to public reply
  const [filter, setFilter] = useState("all"); // Default filter is 'all'
  const [authorNames, setAuthorNames] = useState({});
  const [requesterName, setRequesterName] = useState("");
  const [translatedComments, setTranslatedComments] = useState({}); // Holds translated comments
  const [expandedAccordion, setExpandedAccordion] = useState({});
  const [directSend, setDirectSend] = useState(true);

  useEffect(() => {
    scrollToBottom();

    const fetchAuthorNames = async (comments) => {
      const names = {};

      for (const comment of comments) {
        if (!names[comment.author_id]) {
          try {
            const response = await client.request({
              url: `/api/v2/users/${comment.author_id}.json`,
              type: "GET",
              dataType: "json",
            });

            names[comment.author_id] = response.user.name;
          } catch (error) {
            console.error("Error fetching user details:", error);
            names[comment.author_id] = "Unknown User";
          }
        }
      }

      setAuthorNames(names);
    };

    const fetchRequesterName = async (requesterId) => {
      try {
        const response = await client.request({
          url: `/api/v2/users/${requesterId}.json`,
          type: "GET",
          dataType: "json",
        });
        setRequesterName(response.user.name);
      } catch (error) {
        console.error("Error fetching requester name:", error);
        setRequesterName("Unknown Requester");
      }
    };

    if (ticketData) {
      fetchAuthorNames(ticketData?.comments || []);
      fetchRequesterName(ticketData?.Ticket?.requester_id || "");
    }
  }, [client, ticketData]);

  const scrollToBottom = () => {
    if (commentsEndRef.current) {
      commentsEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const handleFilterSelect = (filterType) => {
    setFilter(filterType);
  };

  const getChannelDetails = (channel) => {
    switch (channel) {
      case "email":
        return {
          icon: <FaEnvelope />,
          text: "EMAIL",
        };
      case "web":
        return {
          icon: <FaGlobe />,
          text: "WEB",
        };
      case "mobile":
        return {
          icon: <FaMobileAlt />,
          text: "MOBILE",
        };
      case "chat":
        return {
          icon: <FaComments />,
          text: "CHAT",
        };
      default:
        return {
          icon: <FaGlobe />,
          text: channel.toUpperCase(),
        };
    }
  };

  const handleCommentTranslate = async () => {
    const response = await textTranslate(newComment);
    setNewComment(response);
  };

  const handleCommentSubmit = async () => {
    const ticketId = ticketData?.Ticket?.id;

    // Create the comment payload
    const payload = {
      ticket: {
        comment: {
          body: newComment,
          public: commentType === "public",
        },
      },
    };

    try {
      if (directSend) {
        await client.request({
          url: `/api/v2/tickets/${ticketId}.json`,
          type: "PUT",
          contentType: "application/json",
          data: JSON.stringify(payload),
        });
      } else {
        await client.invoke("ticket.editor.insert", newComment);
      }
    } catch (error) {
      console.error("Error submitting comment:", error);
    } finally {
      setNewComment("");
    }
  };

  const handleTranslateClick = async (commentId, commentBody) => {
    const translatedText = await textTranslate(commentBody);
    setTranslatedComments((prev) => ({
      ...prev,
      [commentId]: {
        translated: translatedText,
        original: commentBody,
        isTranslated: true,
      },
    }));
  };

  const filteredComments = ticketData?.comments?.filter((comment) => {
    if (filter === "all") return true;
    if (filter === "public") return comment.public === true;
    if (filter === "internal") return comment.public === false;
    return true;
  });

  const toggleAccordion = (commentId) => {
    setExpandedAccordion((prev) => ({
      ...prev,
      [commentId]: !prev[commentId],
    }));
  };
  return (
    <>
      <Card className="border-0">
        <Card.Header className="p-1 px-3 rounded-0">
          <div className=" d-flex justify-content-between">
            <span>
              <h6 className="m-0 p-0">{ticketData?.Ticket?.subject}</h6>
              <p className="m-0 p-0 text-muted" style={{ fontSize: "10px" }}>
                Via {ticketData?.Ticket?.via?.channel}
              </p>
            </span>
            <span className="d-flex">
              <Dropdown onSelect={handleFilterSelect}>
                <Dropdown.Toggle
                  variant="link"
                  id="dropdown-basic"
                  className="text-black p-0 me-3"
                >
                  <LuFilter style={{ fontSize: "20px" }} />
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item eventKey="all">All Comments</Dropdown.Item>
                  <Dropdown.Item eventKey="public">
                    Public Comments
                  </Dropdown.Item>
                  <Dropdown.Item eventKey="internal">
                    Internal Comments
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
              <ExpandModal />
            </span>
          </div>
        </Card.Header>
        <Card.Body
          className="m-1"
          style={{ maxHeight: isModal ? "75vh" : "395px", overflowY: "auto" }}
        >
          {filteredComments.map((comment, index) => {
            const channelDetails = getChannelDetails(comment.via.channel);
            const isUserComment =
              comment.author_id === ticketData?.comments[0]?.author_id;
            const isTranslated = translatedComments[comment.id]?.isTranslated;
            const translatedComment =
              translatedComments[comment.id]?.translated;
            const originalComment =
              translatedComments[comment.id]?.original || comment.html_body;

            return (
              <Row
                key={index}
                className={`mb-3 d-flex ${
                  isUserComment
                    ? "justify-content-start"
                    : "justify-content-end"
                }`}
              >
                <Col xs={9} className={isUserComment ? "p-1" : "text-end p-1"}>
                  <div
                    className={`d-flex align-items-center ${
                      isUserComment ? "" : "flex-row-reverse text-end"
                    }`}
                  >
                    <div>
                      <div
                        className={`d-flex align-items-center ${
                          isUserComment ? "" : "justify-content-end"
                        }`}
                        style={{ fontSize: "14px" }}
                      >
                        <FaUser size={10} className="me-2" />
                        <strong>
                          {authorNames[comment.author_id] || "Loading..."}
                        </strong>
                        {!comment?.public && (
                          <span
                            className="ms-2 badge fw-bold p-1 "
                            style={{
                              background: "#ffb057",
                              color: "#703815",
                              borderRadius: "2px",
                            }}
                          >
                            <span className="my-auto">Internal</span>
                          </span>
                        )}
                        <span className={`ms-2 badge text-bg-light`}>
                          <span className="me-1"> {channelDetails?.icon}</span>

                          <span className="my-auto">
                            {channelDetails?.text}
                          </span>
                        </span>
                      </div>
                      <div
                        className={`text-muted ${
                          isUserComment ? "" : "text-end"
                        }`}
                        style={{ fontSize: "10px" }}
                      >
                        {new Date(comment.created_at).toLocaleString("en-US", {
                          weekday: "short",
                          year: "numeric",
                          month: "long",
                          day: "numeric",
                          hour: "2-digit",
                          minute: "2-digit",
                          second: "2-digit",
                        })}
                      </div>
                    </div>
                  </div>
                  <div
                    className={`p-2 m-0 mt-2 TEXT-BLACK`}
                    style={{
                      borderRadius: "5px",
                      fontSize: "14px",
                      backgroundColor: isUserComment
                        ? comment.public
                          ? "#F8F9F9"
                          : "#FFF7ED"
                        : "#EBF9F9",
                      float: isUserComment ? "left" : "right",
                      textAlign: "left",
                      border: comment.public ? "" : "1px solid #FED6A8",
                    }}
                  >
                    <span
                      className="p-0 m-0"
                      dangerouslySetInnerHTML={{
                        __html: isTranslated
                          ? translatedComment
                          : originalComment,
                      }}
                    ></span>
                    {!translatedComments[comment.id]?.isTranslated && (
                      <div className="d-flex justify-content-end">
                        <Button
                          variant="link"
                          className="p-0 mt-0 fw-semibold grow-icon"
                          style={{ fontSize: "12px" }}
                          onClick={() =>
                            handleTranslateClick(comment.id, comment.html_body)
                          }
                        >
                          Translate
                        </Button>
                      </div>
                    )}
                    {translatedComments[comment.id]?.isTranslated && (
                      <div>
                        <Button
                          variant="link"
                          className="p-0 mt-2 fw-semibold grow-icon"
                          style={{ fontSize: "12px" }}
                          onClick={() => toggleAccordion(comment.id)}
                        >
                          {expandedAccordion[comment.id]
                            ? "Hide Original Text"
                            : "Show Original Text"}
                        </Button>
                        <Accordion
                          activeKey={
                            expandedAccordion[comment.id]
                              ? index.toString()
                              : null
                          }
                        >
                          <Accordion.Collapse eventKey={index.toString()}>
                            <div
                              dangerouslySetInnerHTML={{
                                __html: isTranslated
                                  ? originalComment
                                  : translatedComment,
                              }}
                            ></div>
                          </Accordion.Collapse>
                        </Accordion>
                      </div>
                    )}
                  </div>
                </Col>
              </Row>
            );
          })}
          <div ref={commentsEndRef} />
        </Card.Body>

        <Card.Footer className="m-0 p-0 rounded-0">
          <div className="my-1 d-flex justify-content-between px-1">
            <span className="d-flex">
              <span>
                <Form.Group>
                  <Form.Select
                    size="sm"
                    value={commentType}
                    onChange={(e) => setCommentType(e.target.value)}
                    disabled={ticketData?.Ticket?.status === "closed"}
                  >
                    <option value="public">Public Reply</option>
                    <option value="internal">Internal Comment</option>
                  </Form.Select>
                </Form.Group>
              </span>
              <span
                className="mx-3 my-auto "
                style={{
                  backgroundColor: "grey",
                  width: "1px",
                  height: "28px",
                }}
              ></span>
              <Badge className="my-auto" bg="light" text="dark">
                <FaUser size={10} className="me-2" />
                {requesterName}
              </Badge>
            </span>
            <span className="me-2">
              {!isModal && (
                <Form.Check
                  type="switch"
                  label={
                    <p
                      className="fw-semibold my-auto"
                      style={{ fontSize: "12px" }}
                    >
                      {directSend ? "Send Directly" : "Copy to Input"}
                    </p>
                  }
                  checked={directSend}
                  onChange={() => setDirectSend((prev) => !prev)}
                  disabled={ticketData?.Ticket?.status === "closed"}
                />
              )}
            </span>
          </div>

          <InputGroup>
            <Button
              variant="success"
              onClick={handleCommentTranslate}
              disabled={
                !newComment.trim() || ticketData?.Ticket?.status === "closed"
              }
              className="rounded-0"
            >
              <BsTranslate />
            </Button>
            <Form.Control
              as="textarea"
              rows={2}
              value={newComment}
              placeholder="Write your comment here..."
              onChange={(e) => setNewComment(e.target.value)}
              disabled={ticketData?.Ticket?.status === "closed"}
            />
            <Button
              variant="primary"
              onClick={handleCommentSubmit}
              disabled={
                !newComment.trim() || ticketData?.Ticket?.status === "closed"
              }
              className="rounded-0"
            >
              Submit
            </Button>
          </InputGroup>
        </Card.Footer>
      </Card>
    </>
  );
}

export default TicketComments;
