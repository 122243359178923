import React, { createContext, useState, useEffect, useCallback } from "react";
import { useZafClient } from "../utils/zafClient";
import axios from "axios";
import { ApiConfig } from "../Config/ApiConfig";
const SearchXContext = createContext();

const SearchXProvider = ({ children }) => {
  const [errorMessage, setErrorMessage] = useState("");
  const [resolutions, setResolutions] = useState([]);
  const [hasError, setHasError] = useState(false);
  const [noResolution, setNoResolution] = useState(false);
  const [isLoadingResolution, setIsLoadingResolution] = useState(false);
  const [copied, setCopied] = useState(false);
  const [title, setTitle] = useState("Search your query...");
  const [entitySuggestions, setEntitySuggestions] = useState([]);
  const [aspectSuggestions, setAspectSuggestions] = useState([]);
  const [searchTagsEntity, setSearchTagsEntity] = useState([]);
  const [searchTagsAspect, setSearchTagsAspect] = useState([]);
  const [inputValue, setInputValue] = useState("");

  const [contextData] = useState(
    () => JSON.parse(localStorage.getItem("contextData")) || {}
  );
  const [currentTicket] = useState(
    () => JSON.parse(localStorage.getItem("currentTicket")) || {}
  );
  const [appLocation, setAppLocation] = useState("");

  const client = useZafClient();

  const fetchData = useCallback(
    async ({ query }) => {
      const ticketData = await client?.get?.(["ticket"]);

      const config = {
        headers: { "Access-Control-Allow-Origin": "*" },
        withCredentials: true,
      };
      const searchData = {
        query: query,
        assignee_id:
          appLocation !== "modal"
            ? ticketData?.ticket?.assignee?.user?.id
            : currentTicket?.ticket?.assignee?.user?.id,
        groupName:
          appLocation !== "modal"
            ? ticketData?.ticket?.assignee?.group?.name
            : currentTicket?.ticket?.assignee?.group?.name,
      };
      try {
        setIsLoadingResolution(true);
        setNoResolution(false);
        setHasError(false);

        const response = await axios.post(
          ApiConfig.querySearch,
          searchData,
          config
        );
        if (response.data?.length === 0) {
          const message = "Nothing relevant found";
          setErrorMessage(message);
          setNoResolution(true);
        } else {
          setResolutions(response.data);
          setNoResolution(false);
        }
      } catch (error) {
        const message =
          error.response?.data?.detail?.masked_error ||
          "An unexpected error occurred.";
        setErrorMessage(message);
        setHasError(true);
      } finally {
        setIsLoadingResolution(false);
      }
    },
    [appLocation, client, currentTicket]
  );

  useEffect(() => {
    if (client !== null) {
      const fetchResolutions = async () => {
        setIsLoadingResolution(true);
        setNoResolution(false);
        setHasError(false);
        try {
          const context = await client.context();
          const ticketData = await client.get(["ticket"]);
          const location = context?.location || "Unknown Location";
          setAppLocation(location);

          const resolutionData = {
            subdomain:
              location !== "modal"
                ? context?.account?.subdomain
                : contextData?.account?.subdomain,
            ticketId:
              location !== "modal" ? context?.ticketId : contextData?.ticketId,
            ticketStatus:
              location !== "modal"
                ? ticketData?.ticket?.status
                : currentTicket?.ticket?.status,
            tags:
              location !== "modal"
                ? ticketData?.ticket?.tags
                : currentTicket?.ticket?.tags,
            comments:
              location !== "modal"
                ? ticketData?.ticket?.comments
                : currentTicket?.ticket?.comments,
            assignee_id:
              location !== "modal"
                ? ticketData?.ticket?.assignee?.user?.id
                : currentTicket?.ticket?.assignee?.user?.id,
            requester_id:
              location !== "modal"
                ? ticketData?.ticket?.requester?.id
                : currentTicket?.ticket?.requester?.id,
            groupName:
              location !== "modal"
                ? ticketData?.ticket?.assignee?.group?.name
                : currentTicket?.ticket?.assignee?.group?.name,
            groupId:
              location !== "modal"
                ? ticketData?.ticket?.assignee?.group?.id
                : currentTicket?.ticket?.assignee?.group?.id,
            feature_name: "searchx",
          };

          const config = {
            headers: { "Access-Control-Allow-Origin": "*" },
            withCredentials: true,
          };

          const response = await axios.post(
            ApiConfig.getResolution,
            resolutionData,
            config
          );

          setTitle(response?.data?.topcx_customer_query);
          await fetchData({ query: response?.data?.topcx_customer_query });
        } catch (error) {
          const message =
            error.response?.data?.detail?.masked_error ||
            "An unexpected error occurred.";
          setErrorMessage(message);
          setHasError(true);
        } finally {
          setIsLoadingResolution(false);
        }
      };
      fetchResolutions();
    }
  }, [client, contextData, currentTicket, fetchData]);

  return (
    <SearchXContext.Provider
      value={{
        fetchData,
        errorMessage,
        setErrorMessage,
        resolutions,
        setResolutions,
        hasError,
        setHasError,
        noResolution,
        setNoResolution,
        isLoadingResolution,
        setIsLoadingResolution,
        copied,
        setCopied,
        title,
        setTitle,
        entitySuggestions,
        setEntitySuggestions,
        aspectSuggestions,
        setAspectSuggestions,
        searchTagsEntity,
        setSearchTagsEntity,
        searchTagsAspect,
        setSearchTagsAspect,
        inputValue,
        setInputValue,
        currentTicket,
        appLocation,
      }}
    >
      {children}
    </SearchXContext.Provider>
  );
};

export { SearchXContext, SearchXProvider };
