import React, { useEffect, useState } from "react";
import { Navbar, Container, Form, Row, Col } from "react-bootstrap";
import { FaUser } from "react-icons/fa";
import Loader from "../../Loader/Loader";
import TicketReview from "./Widget/TicketReview";
import HighlightedKPIs from "./Widget/HighlightedKPIs";
import BarLineChart from "./Widget/BarLineChart";
import ProgressTracker from "./Widget/ProgressTracker";
import HeatMap from "./Widget/HeatMap";
import { ApiConfig } from "../../../Config/ApiConfig";
import { useZafClient } from "../../../utils/zafClient";
import axios from "axios";
import { toast } from "react-toastify";
// import { startOfMonth } from "date-fns";
import { startOfMonth, subMonths } from "date-fns";

function QA() {
  const client = useZafClient();

  // const [dateRange] = useState([
  //   startOfMonth(new Date()),
  //   new Date(),
  // ]);

  const [dateRange] = useState([
    startOfMonth(subMonths(new Date(), 1)),
    new Date(),
  ]);

  const [currentUser, setCurrentUser] = useState({});

  const [agentData, setAgentData] = useState({});
  const [isLoadingLevel2Data, setIsLoadingLevel2Data] = useState(false);

  // Format the date range for display
  const formatDate = (date) => {
    return new Date(date).toLocaleDateString("en-US", {
      weekday: "short",
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  };

  useEffect(() => {
    const fetchDataLevel2 = async () => {
      if (!client) return;

      const currentUser = await client?.get?.(["currentUser"]);
      setCurrentUser(currentUser.currentUser);

      const [startDate, endDate] = dateRange;

      const apiData = {
        start_date: startDate,
        end_date: endDate,
        agent_email: currentUser?.currentUser?.email,
      };

      const config = {
        headers: { "Access-Control-Allow-Origin": "*" },
        withCredentials: true,
      };

      try {
        setIsLoadingLevel2Data(true);
        const response = await axios.post(ApiConfig.agentQA, apiData, config);

        setAgentData(response.data);
      } catch (error) {
        toast.error(
          error.response?.data?.detail ? (
            <div>
              <strong>#{error.response.data.detail.error_code}</strong>
              &nbsp;&nbsp;
              {error.response.data.detail.masked_error}
            </div>
          ) : (
            "Something went wrong. Please try again later."
          )
        );
      } finally {
        setIsLoadingLevel2Data(false);
      }
    };
    fetchDataLevel2();
  }, [dateRange, client]);

  return (
    <>
      <Navbar
        bg="white"
        variant="light"
        expand="lg"
        data-bs-theme="light"
        className="bg-body-tertiary"
        sticky="top"
      >
        <Container fluid>
          <Navbar.Brand href="#" className="fw-semibold">
            X-QA
          </Navbar.Brand>

          <Form className="d-flex me-4">
            <div className="d-flex align-items-center ms-4">
              <FaUser />
              <div className="ms-2 text-end">
                <>
                  <div className="fw-bold p-0 m-0" style={{ fontSize: "12px" }}>
                    {currentUser?.name || "Unknown User"}&nbsp;
                    {currentUser?.role
                      ? `(${currentUser?.role
                          ?.toString()
                          ?.charAt(0)
                          ?.toUpperCase()}${currentUser?.role
                          ?.toString()
                          ?.slice(1)})`
                      : ""}
                  </div>
                  <div
                    className="text-muted p-0 m-0"
                    style={{ fontSize: "12px" }}
                  >
                    {currentUser?.email || ""}
                  </div>
                </>
              </div>
            </div>
          </Form>
        </Container>
      </Navbar>
      <>
        <div
          style={{ fontSize: "12px" }}
          className="text-muted fw-semibold my-2 text-center"
        >
          {formatDate(dateRange[0])} - {formatDate(dateRange[1])}
        </div>
        {isLoadingLevel2Data ? (
          <Loader />
        ) : (
          <div>
            <Row className="mb-3">
              <Col lg={12} md={12} sm={12}>
                <TicketReview
                  ticketData={agentData?.ticket_rating_classification}
                />
              </Col>
            </Row>
            <Row className="mb-3">
              <Col lg={12} md={12} sm={12}>
                <HighlightedKPIs kpiData={agentData?.average_scores} />
              </Col>
            </Row>
            <Row className="mb-3">
              <Col lg={12} md={12} sm={12}>
                <HeatMap heatMapData={agentData?.generate_heatmap} />
              </Col>
            </Row>
            <Row className="mb-3">
              <Col lg={12} md={12} sm={12}>
                <BarLineChart
                  targetCount={agentData?.target_count}
                  actualData={agentData?.actual_count}
                />
              </Col>
            </Row>
            <Row className="mb-3">
              <Col lg={12} md={12} sm={12}>
                <ProgressTracker
                  progressData={agentData?.subcategories_track_record}
                />
              </Col>
            </Row>
          </div>
        )}
      </>
    </>
  );
}

export default QA;
