/* eslint-disable no-undef */
import React, { useState, useEffect } from "react";
import { useZafClient } from "../../utils/zafClient";
import Loader from "../Loader/Loader";
import TicketComments from "./Widgets/TicketComments";

export default function TranslateX() {
  const client = useZafClient();
  const [isLoading, setIsLoading] = useState(false);
  const [ticketData, setTicketData] = useState({
    Ticket: {},
    comments: [],
  });
  const [currentTicket, setCurrentTicket] = useState();
  const [isModal, setIsModal] = useState(false);

  useEffect(() => {
    setCurrentTicket(JSON.parse(localStorage.getItem("currentTicket")));
  }, []);

  useEffect(() => {
    if (!client) return;

    let pollingInterval;

    const fetchInitialTicket = async () => {
      try {
        const contextData = await client.context();
        const location = contextData?.location || "Unknown Location";

        // Detect if the app is running in a modal
        const isModalContext = location === "modal";
        setIsModal(isModalContext);

        const ticketId = !isModalContext
          ? contextData?.ticketId
          : currentTicket.ticket.id;

        // Fetch initial ticket data
        fetchTicketData(ticketId);

        if (!isModalContext) {
          // Listen for ticket updates only if not in a modal
          client.on("ticket.updated", () => {
            console.log("Ticket updated, fetching new data...");
            fetchTicketData(ticketId); // Fetch data when the ticket is updated
          });
        } else {
          // Polling mechanism for modal context
          pollingInterval = setInterval(() => {
            console.log("Polling for ticket updates in modal context...");
            fetchTicketData(ticketId);
          }, 10000); // Poll every 10 seconds (adjust as needed)
        }
      } catch (error) {
        console.log(error);
      }
    };

    fetchInitialTicket();

    // Function to fetch ticket data
    const fetchTicketData = async (ticketId) => {
      try {
        // setIsLoading(true);

        // Fetch the ticket details
        const ticketDetailsResponse = await client.request({
          url: `/api/v2/tickets/${ticketId}.json`,
          type: "GET",
          dataType: "json",
        });

        // Fetch the ticket comments
        const ticketCommentsResponse = await client.request({
          url: `/api/v2/tickets/${ticketId}/comments.json`,
          type: "GET",
          dataType: "json",
        });

        setTicketData({
          Ticket: ticketDetailsResponse.ticket,
          comments: ticketCommentsResponse.comments,
        });
      } catch (error) {
        console.error("Error fetching ticket data:", error);
      } finally {
        // setIsLoading(false);
      }
    };

    // Clean up listener and polling on component unmount
    return () => {
      client.off("ticket.updated");
      if (pollingInterval) clearInterval(pollingInterval);
    };
  }, [client, currentTicket]);

  return (
    <div className="container-fluid p-0 m-0 border border-1 border-top-0">
      {isLoading ? (
        <div className="resolution-container">
          <Loader />
        </div>
      ) : (
        <TicketComments
          ticketData={ticketData}
          client={client}
          isModal={isModal}
        />
      )}
    </div>
  );
}
