import React, { useState, useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import ResolutionScreen from "./Resolution/ResolutionScreen";
import Search from "./Search/Search";
import Chatbot from "./Chatbot/Chatbot";
import SmartResponse from "./SmartResponse/SmartResponse";
import TranslateX from "./TranslateX/TranslateX";
import {
  BsEmojiExpressionless,
  BsEmojiAngry,
  BsEmojiAstonished,
  BsEmojiSmile,
  BsEmojiGrin,
} from "react-icons/bs";
import { RiQuestionLine } from "react-icons/ri";
import { MdOutlineAnalytics } from "react-icons/md";
import { FaFileAlt } from "react-icons/fa";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { TicketContext } from "../Context/TicketContext";
import PerformanceModal from "./PerformanceModal/PerformanceModal";
import SummaryModal from "./SummaryX/SummaryModal";
import { ApiConfig } from "../Config/ApiConfig";
import { useZafClient } from "../utils/zafClient";
import axios from "axios";
import { useThirdPartyCookieCheck } from "../hooks/useThirdPartyCookieCheck";
import { Container, Modal, Button } from "react-bootstrap";
import Loader from "./Loader/Loader";
import ExpandModal from "./ExpandModal/ExpandModal";
import Notifications from "./Notifications/Notifications";
import { ChatbotContext } from "../Context/ChatBotContext";
import { IoInformationCircleOutline } from "react-icons/io5";

export default function Navtab() {
  const {
    customerQuery,
    customerSentiment,
    setCustomerQuery,
    setCustomerSentiment,
    handleShowPerformanceModal,
    handleOpenSummaryModal,
  } = useContext(TicketContext);
  const { setIsStreaming } = useContext(ChatbotContext);

  const [activeTab, setActiveTab] = useState("TopX");
  const client = useZafClient();

  const [areCookiesEnabled, setAreCookiesEnabled] = useState(null);
  const cookieCheck = useThirdPartyCookieCheck();

  const [contextData] = useState(
    () => JSON.parse(localStorage.getItem("contextData")) || {}
  );
  const [currentTicket] = useState(
    () => JSON.parse(localStorage.getItem("currentTicket")) || {}
  );
  const [appLocation, setAppLocation] = useState("");
  const [ticketStatus, setTicketStatus] = useState("");

  const [tooltipData, setTooltipData] = useState(null);

  useEffect(() => {
    if (cookieCheck !== null) {
      // Check if cookie status is determined
      setAreCookiesEnabled(cookieCheck);
    }
  }, [cookieCheck]);

  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
    if (tabName !== "Chatbot") {
      setIsStreaming(false);
    }
  };

  useEffect(() => {
    const fetchTooltipData = async () => {
      const config = {
        headers: { "Access-Control-Allow-Origin": "*" },
        withCredentials: true,
      };
      try {
        const response = await axios.get(ApiConfig.tooltips, config);
        setTooltipData(response?.data);
      } catch (error) {}
    };
    fetchTooltipData();
  }, []);

  useEffect(() => {
    if (client !== null) {
      const fetchTicketData = async () => {
        try {
          const context = await client?.context?.();
          const ticketData = await client?.get?.(["ticket"]);
          const location = context?.location || "Unknown Location";
          setAppLocation(location);
          setTicketStatus(
            location !== "modal"
              ? ticketData?.ticket?.status
              : currentTicket?.ticket?.status
          );

          const resolutionData = {
            subdomain:
              location !== "modal"
                ? context?.account?.subdomain
                : contextData?.account?.subdomain,
            ticketId:
              location !== "modal" ? context?.ticketId : contextData?.ticketId,
            ticketStatus:
              location !== "modal"
                ? ticketData?.ticket?.status
                : currentTicket?.ticket?.status,
            tags:
              location !== "modal"
                ? ticketData?.ticket?.tags
                : currentTicket?.ticket?.tags,
            comments:
              location !== "modal"
                ? ticketData?.ticket?.comments
                : currentTicket?.ticket?.comments,
            assignee_id:
              location !== "modal"
                ? ticketData?.ticket?.assignee?.user?.id
                : currentTicket?.ticket?.assignee?.user?.id,
            requester_id:
              location !== "modal"
                ? ticketData?.ticket?.requester?.id
                : currentTicket?.ticket?.requester?.id,
            groupName:
              location !== "modal"
                ? ticketData?.ticket?.assignee?.group?.name
                : currentTicket?.ticket?.assignee?.group?.name,
            groupId:
              location !== "modal"
                ? ticketData?.ticket?.assignee?.group?.id
                : currentTicket?.ticket?.assignee?.group?.id,
            feature_name: "searchx",
          };

          const config = {
            headers: { "Access-Control-Allow-Origin": "*" },
            withCredentials: true,
          };

          const response = await axios.post(
            ApiConfig.getResolution,
            resolutionData,
            config
          );

          setCustomerQuery(response?.data?.topcx_customer_query);
          setCustomerSentiment(response?.data?.topcx_sentiment);
        } catch (error) {}
      };
      fetchTicketData();
    }
  }, [
    client,
    setCustomerQuery,
    setCustomerSentiment,
    contextData,
    currentTicket,
  ]);

  const sentimentMapping = {
    "Very Positive": <BsEmojiGrin className="text-success fs-5" />,
    Positive: <BsEmojiSmile className="text-success-emphasis fs-5" />,
    Neutral: <BsEmojiExpressionless className="text-warning fs-5" />,
    Negative: <BsEmojiAstonished className="text-danger-emphasis fs-5" />,
    "Very Negative": <BsEmojiAngry className="text-danger fs-5" />,
  };
  const defaultSentimentIcon = <RiQuestionLine className="fs-5" />;
  let sentimentIcon =
    sentimentMapping[customerSentiment] || defaultSentimentIcon;

  if (areCookiesEnabled === null) {
    // Render a loading or placeholder UI while checking for cookies
    return (
      <div className="alert alert-danger" role="alert">
        <Loader />
      </div>
    );
  }

  if (!areCookiesEnabled) {
    return (
      <div className="alert alert-danger" role="alert">
        <Modal show={true} onHide={() => {}} size="sm" centered>
          <Modal.Body className="text-center">
            <p>
              Third-party cookies are disabled. Please enable them and reload
              the page.
            </p>
            <Button
              variant="primary"
              onClick={() => window.location.reload()}
              className="mt-2"
            >
              Reload
            </Button>
          </Modal.Body>
        </Modal>
      </div>
    );
  }

  const tooltipContent =
    tooltipData?.[activeTab.toLowerCase()]?.[
      `${activeTab.toLowerCase()}_overview`
    ] || "Tooltip not available";

  return (
    <div className="card">
      <div className="card-header p-0 m-0">
        <Container
          fluid
          className="rounded-top bg-white overflow-hidden p-0 d-flex justify-content-evenly"
        >
          <div className="my-auto ">
            <MdOutlineAnalytics
              data-tooltip-id="performance-icon"
              className="grow-icon"
              onClick={handleShowPerformanceModal}
              style={{
                cursor: "pointer",
                fontSize: "1.5rem",
                width: "25px",
                height: "25px",
              }}
            />

            <FaFileAlt
              data-tooltip-id="summary-icon"
              className="grow-icon"
              onClick={handleOpenSummaryModal}
              style={{
                cursor: "pointer",
                fontSize: "1.5rem",
                width: "20px",
                height: "20px",
                marginLeft: "8px",
              }}
            />
          </div>

          <div className="w-75">
            <p
              data-tooltip-id="customer-query"
              className="my-auto px-3 text-center"
              id="customerQuery"
            >
              {customerQuery}
            </p>
            <nav className="navbar sticky-top bg-body-tertiary d-flex justify-content-center p-0 rounded rounded-0">
              <h6
                data-tooltip-id="sentiment"
                className="p-0 px-3 m-0 my-auto"
                id="chat-heading"
              >
                Customer&apos;s Sentiment: {sentimentIcon}
              </h6>
            </nav>
          </div>

          <div className="my-auto ">
            {appLocation !== "modal" ? <ExpandModal /> : <></>}
            <Notifications />
          </div>
        </Container>

        {customerQuery && (
          <ReactTooltip
            style={{ zIndex: "9999" }}
            id="customer-query"
            place="bottom"
            content={`This is the customer's query`}
            delayShow={800}
          />
        )}

        <ReactTooltip
          id="performance-icon"
          place="top"
          content={`My Performance`}
          style={{ zIndex: "9999" }}
          delayShow={800}
        />
        <ReactTooltip
          id="summary-icon"
          place="top"
          content={`Ticket Summary`}
          style={{ zIndex: "9999" }}
          delayShow={800}
        />
        {customerSentiment && (
          <ReactTooltip
            id="sentiment"
            place="bottom"
            content={`The customer is feeling "${customerSentiment}" based on their query.`}
            style={{ zIndex: "9999" }}
            delayShow={800}
          />
        )}
      </div>

      <div className="card-body p-1">
        <ul className="nav nav-underline nav-fill">
          <li className="nav-item" data-tooltip-id="TopX">
            <Link
              className={`nav-link ${activeTab === "TopX" ? "active" : ""} p-0`}
              onClick={() => handleTabClick("TopX")}
            >
              TopX
              <ReactTooltip
                style={{
                  zIndex: "9999",
                }}
                id="TopX"
                place="top"
                content={`Get possible resolutions based on legacy ticket data. `}
                delayShow={800}
              />
            </Link>
          </li>
          <li className="nav-item" data-tooltip-id="SearchX">
            <Link
              className={`nav-link ${
                activeTab === "SearchX" ? "active" : ""
              } p-0`}
              onClick={() => handleTabClick("SearchX")}
            >
              SearchX
              <ReactTooltip
                style={{
                  zIndex: "9999",
                }}
                id="SearchX"
                place="top"
                content={`Search and filter tickets with ease using SearchX. `}
                delayShow={800}
              />
            </Link>
          </li>
          <li className="nav-item" data-tooltip-id="BotX">
            <Link
              className={`nav-link ${activeTab === "BotX" ? "active" : ""} p-0`}
              onClick={() => handleTabClick("BotX")}
            >
              BotX
              <ReactTooltip
                style={{
                  zIndex: "9999",
                }}
                id="BotX"
                place="top"
                content={`Get answers from the knowledge base using the AI bot.`}
                delayShow={800}
              />
            </Link>
          </li>
          <li className="nav-item" data-tooltip-id="EmailX">
            <Link
              className={`nav-link ${
                activeTab === "EmailX" ? "active" : ""
              } p-0`}
              onClick={() => handleTabClick("EmailX")}
            >
              EmailX
              <ReactTooltip
                style={{
                  zIndex: "9999",
                }}
                id="EmailX"
                place="top"
                content={`Draft an email based on the ticket conversation, top resolutions, bot solutions, or manual instructions.`}
                delayShow={800}
              />
            </Link>
          </li>
          {/* <li className="nav-item" data-tooltip-id="TranslateX">
            <Link
              className={`nav-link ${
                activeTab === "TranslateX" ? "active" : ""
              } p-0`}
              onClick={() => handleTabClick("TranslateX")}
            >
              TranslateX
              <ReactTooltip
                style={{
                  zIndex: "9999",
                }}
                id="TranslateX"
                place="top"
                content={`Draft an email based on the ticket conversation, top resolutions, bot solutions, or manual instructions.`}
                delayShow={800}
              />
            </Link>
          </li> */}
        </ul>
        <hr className="m-0 p-0" />

        {activeTab === "TopX" && <ResolutionScreen />}
        {activeTab === "SearchX" && <Search />}
        {activeTab === "BotX" && <Chatbot />}
        {activeTab === "EmailX" && <SmartResponse />}
        {activeTab === "TranslateX" && <TranslateX />}

        <div
          className="mt-1"
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <div> </div>
          <p
            className="text-center text-muted m-0"
            style={{ fontSize: "0.6rem" }}
          >
            TopCX can make mistakes. Consider checking important information.
          </p>

          <ReactTooltip
            id="Suggest"
            place="bottom"
            style={{ zIndex: "9999" }}
            delayShow={800}
          >
            <div
              style={{
                fontSize: "10px",
                wordWrap: "break-word",
                whiteSpace: "normal",
                maxWidth: "400px",
              }}
            >
              <p>{tooltipContent}</p>
            </div>
          </ReactTooltip>
          <IoInformationCircleOutline data-tooltip-id="Suggest" />
        </div>
      </div>
      <PerformanceModal ticketStatus={ticketStatus} />
      <SummaryModal />
    </div>
  );
}
