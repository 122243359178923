import React, { useEffect, useState, useContext } from "react";
import { Nav, Navbar, Container, Form, Row, Col } from "react-bootstrap";
import { ApiConfig } from "../../../Config/ApiConfig";
import { useZafClient } from "../../../utils/zafClient";
import Loader from "../../Loader/Loader";
import axios from "axios";
import { toast } from "react-toastify";
import TicketComments from "./Widget/TicketComments";
import QAScoreAccordion from "./Widget/QAScoreAccordion";
import QaReviews from "./Widget/QAReviews";
import { FaUser } from "react-icons/fa";
import { TicketContext } from "../../../Context/TicketContext";

function TicketQA() {
  const client = useZafClient();
  const { ticketID, setTicketID, notificationTicketQA } =
    useContext(TicketContext);

  const [ticketData, setTicketData] = useState({});
  const [currentUser, setCurrentUser] = useState({});
  const [isLoadingLevel2Data, setIsLoadingLevel2Data] = useState(false);

  const [contextData] = useState(
    () => JSON.parse(localStorage.getItem("contextData")) || {}
  );

  const [error, setError] = useState(false);

  useEffect(() => {
    const fetchDataLevel2 = async () => {
      if (!client) return;

      const context = await client.context();
      const currentUser = await client?.get?.(["currentUser"]);
      const location = context?.location || "Unknown Location";

      if (!notificationTicketQA) {
        setTicketID(context?.ticketId);
      }

      setCurrentUser(currentUser?.currentUser);
      const apiData = {
        ticket_id: notificationTicketQA
          ? ticketID
          : location !== "modal"
          ? context?.ticketId
          : contextData?.ticketId,
        logged_in_user: currentUser?.currentUser?.email,
      };

      const config = {
        headers: { "Access-Control-Allow-Origin": "*" },
        withCredentials: true,
      };

      try {
        setIsLoadingLevel2Data(true);
        setError(false);
        const response = await axios.post(ApiConfig.ticketQA, apiData, config);
        setTicketData(response.data);
      } catch (error) {
        toast.error(
          error.response?.data?.detail ? (
            <div>
              <strong>#{error.response.data.detail.error_code}</strong>
              &nbsp;&nbsp;
              {error.response.data.detail.masked_error}
            </div>
          ) : (
            "Something went wrong. Please try again later."
          )
        );

        setError(true);
      } finally {
        setIsLoadingLevel2Data(false);
      }
    };
    fetchDataLevel2();
  }, [client, notificationTicketQA, setTicketID, ticketID, contextData]);

  const [selectedKpis, setSelectedKpis] = useState({});

  const handleKpiSelect = (selectedKpis) => {
    setSelectedKpis(selectedKpis);
  };

  return (
    <>
      <Navbar
        bg="white"
        variant="light"
        expand="lg"
        data-bs-theme="light"
        className="bg-body-tertiary"
        sticky="top"
      >
        <Container fluid>
          <Navbar.Brand className="fw-semibold" href="#">
            X-QA
          </Navbar.Brand>

          <Nav className="me-auto my-2 my-lg-0"></Nav>
          <Form className="d-flex me-4">
            <div className="d-flex align-items-center ms-4">
              <FaUser />
              <div className="ms-2 text-end">
                <div className="fw-bold p-0 m-0" style={{ fontSize: "12px" }}>
                  {ticketData?.agent_info?.name || "Unknown User"}&nbsp;
                  {ticketData?.agent_info
                    ? `(${ticketData?.agent_info?.role
                        .charAt(0)
                        .toUpperCase()}${ticketData?.agent_info?.role.slice(
                        1
                      )})`
                    : ""}
                </div>
                <div
                  className="text-muted p-0 m-0"
                  style={{ fontSize: "12px" }}
                >
                  {ticketData?.agent_info?.email || ""}
                </div>
              </div>
            </div>
          </Form>
        </Container>
      </Navbar>

      <>
        <div
          style={{ fontSize: "12px" }}
          className="text-muted fw-semibold my-2 text-center"
        >
          Ticket ID - #{ticketID}
        </div>
        {isLoadingLevel2Data ? (
          <Loader />
        ) : (
          <div className="mt-3">
            <Row className="mb-3">
              <Col xs={12}>
                <TicketComments
                  ticketId={ticketID}
                  ticketData={ticketData || {}}
                  selectedKpis={selectedKpis}
                />
              </Col>
            </Row>
            <Row className="mb-3">
              <Col xs={12}>
                <QAScoreAccordion
                  topCxQaScores={ticketData?.conversations?.topcx_qa_scores}
                  onKpiSelect={handleKpiSelect}
                />
              </Col>
            </Row>
            <Row className="mb-3">
              <Col xs={12}>
                <QaReviews
                  reviews={ticketData?.qa_chat}
                  ticketId={ticketID}
                  currentUser={currentUser}
                  ticketData={ticketData}
                  error={error}
                />
              </Col>
            </Row>
          </div>
        )}
      </>
    </>
  );
}

export default TicketQA;
